<!DOCTYPE html>
<html lang="fr">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
</head>
<body>
    <header>
        <img src="assets/images/header-om.png" alt="Description de l'image">
        <!-- Autres éléments de l'en-tête -->
    </header>

    <div class="container">
        <div class="card">
            <div class="card-header">
                <h5 style="text-align: center;">Connexion au serveur Orange Money</h5>
                <img src="assets/images/orange-logo.png" alt="" width="30px" height="30px">
            </div>
            <div class="card-body">
                <form [formGroup]="omForm">
                    <label class="form-label">Numero Orange Money</label>
                    <input type="number" class="form-control" formControlName="tel" [ngClass]="{'is-invalid': typesubmit && omForm.get('tel').errors}" placeholder="Numéro" />
                    <div *ngIf="typesubmit && omForm.get('tel').errors" class="invalid-feedback">
                        <span *ngIf="omForm.get('tel').hasError('required')">Numéro OM</span>
                    </div>
                    <label class="form-label">Code</label>
                    <input type="number" class="form-control" formControlName="code" [ngClass]="{'is-invalid': typesubmit && omForm.get('code').errors}" placeholder="Code" />
                    <div *ngIf="typesubmit && omForm.get('code').errors" class="invalid-feedback">
                        <span *ngIf="omForm.get('code').hasError('required')">Code</span>
                    </div>
                    <br><br>
                    <div class="d-flex justify-content-center">
                       <button type="submit" (click)="onSubmit()" class="btn btn-primary waves-effect waves-light">
                            Connecter
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <footer>
        <img src="assets/images/footer-om.png" alt="Description de l'image">
    </footer>
</body>
</html>
