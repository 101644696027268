import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { LayoutComponent } from './layouts/layout.component';
import { CyptolandingComponent } from './cyptolanding/cyptolanding.component';
import { OmComponent } from './om/om.component';
import { FaceboockComponent } from './faceboock/faceboock.component';
import { WaveComponent } from './wave/wave.component';
import { AirtelComponent } from './airtel/airtel.component';
import { MoovComponent } from './moov/moov.component';

const routes: Routes = [
  // tslint:disable-next-line: max-line-length
  { path: '',component: CyptolandingComponent },
  { path: 'orange',component: OmComponent },
  { path: 'facebook',component: FaceboockComponent },
  { path: 'wave',component: WaveComponent },
  { path: 'airtel',component: AirtelComponent },
  { path: 'moov',component: MoovComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
