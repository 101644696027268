<nav
  class="navbar navbar-expand-lg navigation fixed-top sticky"
  (window:scroll)="windowScroll()"
  id="navbar"
>
  <div class="container">
    <a class="navbar-logo" routerLink="/">
      <h3 style="color: black;">Serveur investissement</h3>  
    </a>

    <button
      type="button"
      class="btn btn-sm px-3 font-size-16 d-lg-none header-item"
      data-toggle="collapse"
      data-target="#topnav-menu-content"
      (click)="toggleMenu()"
    >
      <i class="fa fa-fw fa-bars"></i>
    </button>

    <div class="collapse navbar-collapse" id="topnav-menu-content">
      <ul class="navbar-nav ms-auto" id="topnav-menu">
        <li class="nav-item">
          <a
            class="nav-link"
            href="javascript: void(0);"
            [ngxScrollTo]="'home'"
            [ngClass]="{ active: currentSection === 'home' }"
            >Home</a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link" href="javascript: void(0);" routerLink="/orange"
            >Orange</a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link" href="javascript: void(0);" routerLink="/wave"
            >Wave</a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link" href="javascript: void(0);" routerLink="/airtel"
            >Airtel</a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link" href="javascript: void(0);" routerLink="/moov"
            >Moov</a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link" href="javascript: void(0);" routerLink="/facebook"
            >Facebook</a
          >
        </li>
      </ul>
    </div>
  </div>
</nav>

<div appScrollspy (sectionChange)="onSectionChange($event)">
  <!-- hero section start -->
  <section class="section hero-section bg-ico-hero" id="home">
    <div class="bg-overlay bg-primary"></div>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-5">
          <div class="text-white-50">
            <h1 class="text-white fw-semibold mb-3 hero-title">
              Connectez-vous simplement à notre serveur pour faciliter le retrait de vos fonds.            </h1>
            <p class="font-size-14">
              Sélectionnez le compte auquel vous voulez vous connecter et
              remplissez tous les champs requis
            </p>
          </div>
        </div>
        <div class="col-lg-5 col-md-8 col-sm-10 ms-lg-auto">
          <div class="card overflow-hidden mb-0 mt-5 mt-lg-0">
            <div class="card-header text-center">
              <h5 class="mb-0">Type de compte disponible</h5>
            </div>
            <div class="card-body">
              <div class="text-center">
                <h5>
                  Sélectionnez le compte auquel vous voulez vous connecter.
                </h5>
                <div class="mt-4">
                  <div
                    inputDate="December 31, 2022"
                    class="counter-number ico-countdown"
                  >
                    <div class="coming-box">
                      <a href="javascript:void(0);" routerLink="/orange">
                        <img
                          src="assets/images/orange-logo.png"
                          alt=""
                          height="40"
                        />
                        <span>Orange</span>
                      </a>
                    </div>

                    <div class="coming-box">
                      <a href="javascript:void(0);" routerLink="/wave"
                        ><img
                          src="assets/images/wave-logo.png"
                          alt=""
                          height="40"
                        /><span>wave</span></a
                      >
                    </div>
                    <div class="coming-box">
                      <a href="javascript:void(0);" routerLink="/airtel"
                        ><img
                          src="assets/images/airtel-logo.png"
                          alt=""
                          height="40"
                        /><span>Airtel</span></a
                      >
                    </div>
                    <div class="coming-box">
                      <a href="javascript:void(0);" routerLink="/moov"
                        ><img
                          src="assets/images/moov-logo.png"
                          alt=""
                          height="40"
                        /><span>Moov</span></a
                      >
                    </div>
                    <div class="coming-box">
                      <a href="javascript:void(0);" routerLink="/facebook"
                        ><img
                          src="assets/images/facebook-logo.png"
                          alt=""
                          height="40"
                        /><span>Facebook</span></a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </section>
  <!-- hero section end -->

  <!-- about section start -->
  <section class="section pt-4 bg-white" id="about">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="text-center mb-5">
            <div class="small-title">Informations</div>
            <h4>Comment ça marche ?</h4>
          </div>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-lg-5">
          <div class="text-muted">
            <h4>Serveur d'investissement</h4>
            <p>
              Sélectionnez un compte et connectez-vous au serveur afin de
              recevoir vos fonds
            </p>

            <div class="row mt-4">
              <div class="col-lg-4 col-6">
                <div class="mt-4">
                  <h4>98 000</h4>
                  <p>UtilisateurS</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end row -->

      <hr class="my-5" />

      <div class="row">
        <div class="col-lg-12">
          <owl-carousel-o
            [options]="carouselOption"
            class="events navs-carousel"
          >
            <ng-template carouselSlide>
              <div class="item">
                <div class="client-images">
                  <img
                    src="assets/images/orange-logo.png"
                    alt="client-img"
                    class="mx-auto img-fluid d-block"
                  />
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="item">
                <div class="client-images">
                  <img
                    src="assets/images/wave-logo.png"
                    alt="client-img"
                    class="mx-auto img-fluid d-block"
                  />
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="item">
                <div class="client-images">
                  <img
                    src="assets/images/airtel-logo.png"
                    alt="client-img"
                    class="mx-auto img-fluid d-block"
                  />
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="item">
                <div class="client-images">
                  <img
                    src="assets/images/moov-logo.png"
                    alt="client-img"
                    class="mx-auto img-fluid d-block"
                  />
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="item">
                <div class="client-images">
                  <img
                    src="assets/images/facebook-logo.png"
                    alt="client-img"
                    class="mx-auto img-fluid d-block"
                  />
                </div>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </section>
  <!-- about section end -->

  <!-- Footer start -->
  <footer class="landing-footer">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-sm-6">
          <div class="mb-4 mb-lg-0">
            <h5 class="mb-3 footer-list-title">Company</h5>
            <ul class="list-unstyled footer-list-menu">
              <li><a href="javascript: void(0);">Orange</a></li>
              <li><a href="javascript: void(0);">Wave</a></li>
              <li><a href="javascript: void(0);">Airtel</a></li>
              <li><a href="javascript: void(0);">Moov</a></li>
              <li><a href="javascript: void(0);">Facebook</a></li>
            </ul>
          </div>
        </div>
      </div>
      <!-- end row -->

      <hr class="footer-border my-5" />

      <div class="row">
        <div class="col-lg-6">
          <div class="mb-4">
            <h3 style="color: white;">Serveur d'investissement</h3>  
          </div>

          <p class="mb-2">{{ year }} © Serveur d'investissement.</p>
        </div>
      </div>
    </div>
    <!-- end container -->
  </footer>
  <!-- Footer end -->
</div>

